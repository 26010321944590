<template>
  <div>
    <div v-if="loading">
      <b-card class="text-center mt-3">
        <!-- LOADING -->
        <b-spinner variant="success" type="grow" label="Spinning" />
      </b-card>
    </div>
    <div v-else>
      <!-- SOMETHING -->
      <div v-if="!done && product.active">
        <Form :produto="product" @setDone="setDone" />
      </div>
      <div v-else-if="!product.active">
        <Error />
      </div>
      <div v-else>
        <Done />
      </div>
    </div>
    <div v-if="!done"></div>
  </div>
</template>

<script>
import Form from "./components/Form.vue";
import Error from "./components/Error.vue";
import Done from "./components/Done.vue";

import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  components: {
    Form,
    Error,
    Done,
  },
  data() {
    return {
      loading: true,
      product: {},
      done: false,
    };
  },
  methods: {
    async checkRegister() {
      if (!this.$route.params.id) return;
      await axios
        .post(`${baseApiUrl}/produto`, {
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success && res.data.data.active) {
            this.product = res.data.data;
          }
        })
        .catch((err) => console.log(err));
      this.loading = false;
    },
    setDone() {
      this.done = true;
    },
  },
  mounted() {
    this.checkRegister();
  },
};
</script>

<style>
</style>