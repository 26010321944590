<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card
          border-variant="success"
          show
          class="text-center m-2"
        >
          <h1>Cadastro finalizado com Sucesso !!</h1>
          <h3 class="my-2">Em breve a coordenação de vendas entrará em contato</h3>
          <a
            href="https://api.whatsapp.com/send?phone=5511939447625&text=Solicito%20o%20link%20"
          >
            Qualquer dúvida entre em contato com (11) 93944-7625 </a
          ><br />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink
  },
};
</script>

<style>
</style>