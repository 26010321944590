<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card
          border-variant="danger"
          show
          v-if="!hasId"
          class="text-center m-2"
        >
          <h1 class="test-red">Falha de identificação !</h1>
          <a
            href="https://api.whatsapp.com/send?phone=5511939447625&text=Solicito%20o%20link%20"
          >
            Por favor entre em contato com (11) 93944-7625 </a
          ><br />
          <b-link :to="{ name: 'login' }"><span> voltar </span> </b-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink
  },
};
</script>

<style>
</style>